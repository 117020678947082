<template>
    <div class="text-left container">
      <BaseHeader :title="'Bulk Notifications'"></BaseHeader>
  
      <BaseLoading
        style="min-height: 400px; height: 90vh"
        v-if="loading"
      ></BaseLoading>
  
      <section v-else>
        <div class="card mt-2 border-0 bg-white ">
          <div class="card-body" style>
            <form >
              <div class="form-group row">
                <label
                  for="inputEmail3"
                  class="
                    col-sm-2 col-form-label
                    text-left text-sm-right
                    font-weight-semibold
                  "
                  >Subject</label
                >
                <div class="col-sm-9 col-md-8 col-lg-6">
                  <input
                    required
                    type="text"
                    v-model="mailingForm.subject"
                    class="form-control height-60 "
                    id="inputEmail3"
                  />
                </div>
              </div>
  
              <div class="form-group row">
                <label
                  for="inputEmail3"
                  class="
                    col-sm-2 col-form-label
                    text-left text-sm-right
                    font-weight-semibold
                  "
                  >Message</label
                >
                <div class="col-sm-9 col-md-8 col-lg-6">
                  <vue-editor v-model="mailingForm.message"></vue-editor>
                  <v-alert
                    class="mt-1"
                    v-show="mailingForm.message == '' && message_required"
                    dense
                    text
                    type="error"
                  >
                    The message field is required
                  </v-alert>
                </div>
              </div>
  
              <div class="form-group row">
                <label
                  for="inputEmail3"
                  class="
                    col-sm-2 col-form-label
                    text-left text-sm-right
                    font-weight-semibold
                  "
                  >Send to</label
                >
                <div class="col-sm-9 col-md-8 col-lg-6">
                  <select
                    required
                    v-model="mailingForm.send_to"
                    class="form-control"
                  >
                    <option value="Writers">All Writers</option>
                    <!-- <option value="Somewriters">Select from writers</option>
                    <option value="Someclients">Select from clients</option> -->
                    <option value="Clients">All clients</option>
                  </select>
                  <p class="my-1 text-muted">
                    <span class="icon-help"></span> You can send emails to all
                    writers or to clients of a specific website.
                  </p>
                </div>
              </div>
  
              <div class="form-group row" v-if="mailingForm.send_to == 'Somewriters'">
                <label
                  for="inputEmail3"
                  class="
                    col-sm-2 col-form-label
                    text-left text-sm-right
                    font-weight-semibold
                  "
                  >Select Writers:</label
                >
                <div class="col-sm-9 col-md-8 col-lg-6">
                  <vue-select
                    v-model="mailingForm.site_id"
                    placeholder="Start typing to search"
                    :options="writers"
                    :reduce="(writer) => writer.id"
                    label="first_name"
                    taggable
                    multiple
                    push-tags
                  >
                  </vue-select>
  
                  <p class="my-1 text-muted">
                    <span class="icon-help"></span> Send to all clients of the
                    website selected above.
                  </p>
                </div>
              </div>
  
              <div class="form-group row" v-if="mailingForm.send_to == 'Someclients'">
                <label
                  for="inputEmail3"
                  class="
                    col-sm-2 col-form-label
                    text-left text-sm-right
                    font-weight-semibold
                  "
                  >Select Clients:</label
                >
                <div class="col-sm-9 col-md-8 col-lg-6">
                  <vue-select
                    v-model="mailingForm.site_id"
                    placeholder="Start typing to search"
                    :options="clients"
                    :reduce="(client) => client.id"
                    label="names"
                    taggable
                    multiple
                    push-tags
                  >
                  </vue-select>
  
                  <p class="my-1 text-muted">
                    <span class="icon-help"></span> Send to all clients of the
                    website selected above.
                  </p>
                </div>
              </div>
  
              <!-- <div class="form-group row" v-if="mailingForm.send_to == 'Clients'">
                <label
                  for="inputEmail3"
                  class="
                    col-sm-2 col-form-label
                    text-left text-sm-right
                    font-weight-semibold
                  "
                  >Websites' client:</label
                >
                <div class="col-sm-9 col-md-8 col-lg-6">
                  <vue-select
                    v-model="mailingForm.site_id"
                    placeholder="Start typing to search"
                    :options="sites"
                    :reduce="(site) => site.id"
                    label="name"
                    taggable
                    multiple
                    push-tags
                  >
                  </vue-select>
  
                  <p class="my-1 text-muted">
                    <span class="icon-help"></span> Send to all clients of the
                    website selected above.
                  </p>
                </div>
              </div>
   -->
              <div class="row">
                <div class="col-sm-9 col-md-8 col-lg-6 offset-sm-2">
                  <!-- <button @click.prevent="submitMailForm" :loading="mailingForm.busy" type="submit" class="btn-add "
                    >{{mailingForm.busy  ? 'Sending Notifications .....' : 'Send as Notification' }}   </button
                  > -->
                  <button @click.prevent="submitNewsForm" :loading="mailingForm.busy" type="submit" class="btn-add "
                    >{{mailingForm.busy  ? 'Sending News.....' : 'Send bulk News' }}   </button
                  >
                </div>
              </div>
              <BaseOverlay v-if="mailingForm.busy "></BaseOverlay>
  
            </form>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { mapActions, mapState } from "vuex";
  import Alerts from "../../../mixins/alerts";
  export default {
    computed: {
      ...mapState("mailing", ["loading", "mailingForm"]),
      ...mapState("sites", ["sites"]),
      ...mapState("writers", ["writers"]),
      ...mapState("clientusers", ["clients"]),
    },
    data() {
      return {
        message_required: false,
        loading_msg:false
      };
    },
    methods: {
      ...mapActions("mailing", ["sendMail"]),
      ...mapActions("sites", ["getSites"]),
      ...mapActions("clientusers", ["_getClients"]),
      ...mapActions("writers", ["getWriters"]),
  
      setSite() {
        this.mailingForm.site_id = this.$store.state.site_id;
      },
  
      submitMailForm() {
        if (this.mailingForm.message == "") {
          this.message_required = true;
          return;
        }
        this.loading_msg = true;
  
        let url = `/Notifications/Message${this.mailingForm.send_to}`;
        console.log("url", url);
        this.sendMail(url)
          .then(() => {
            this.successNoti("Recipients mailed successfully.");
            this.mailingForm.reset();
          })
          .catch((err) => {
            console.log("err", err);
            this.errorNoti(err || "Could not mail recipients");
          });
        this.message_required = false;
        this.loading_msg = false;
  
      },
  
      submitNewsForm() {
        if (this.mailingForm.message == "") {
          this.message_required = true;
          return;
        }
        this.loading_msg = true;
  
        let url = `/Notifications/PostNewsClients`;
        console.log("url", url);
        this.sendMail(url)
          .then(() => {
            this.successNoti("Recipients sent news successfully.");
            this.mailingForm.reset();
          })
          .catch((err) => {
            console.log("err", err);
            this.errorNoti(err || "Could not send news to recipients");
          });
        this.message_required = false;
        this.loading_msg = false;
  
      },
    },
    async mounted() {
      await this.getSites();
      await this.getWriters();
      await this._getClients();
    },
    mixins: [Alerts],
  };
  </script>
  
  <style>
  
  .btn-add:focus{
    outline: none!important;
  }
  </style>